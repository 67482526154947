

class ChatGPTAIService {
  constructor() {
    this.conversation = [];
    this.conversationWithoutPrompt = [];
  }

  async sendToChatGPT() {
    const maxRetries = 7;
    let attempts = 0;

    while (attempts < maxRetries) {
      try {
        const response = await fetch(`${process.env.GPT_SERVER}/api/english/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            conversation: this.conversation
          }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        const lastMessage = data.choices[0].message.content.trim();

        return lastMessage;

      } catch (error) {
        attempts++;
        console.error(`Attempt ${attempts} failed:`, error);

        if (attempts === maxRetries) {
          return 'Your connection is not stable, try to reload the page or check your wifi settings';
        }
      }
    }
  }

  addMessageToConversation(role, content) {
    this.conversation.push({ role, content });
  }
  addMessageWithoutPrompt(role, content) {
    this.conversationWithoutPrompt.push({ role, content });
  }


}



export default new ChatGPTAIService();
