
import { View, Text, TextInput, StyleSheet } from 'react-native';
import React, { useContext } from 'react';
import AppContext from '../AppContext';
import { Platform } from 'react-native';
import { Dimensions } from 'react-native';
export default function InputField({ onPasswordChange, onFullNameChange }) {

    const { responsiveHeight } = useContext(AppContext);
    return (
        <View style={{ ...styles.inputfield, width: responsiveHeight * 0.3 }}>
            <Text style={{ ...styles.loginTitle, width: 0.1 * responsiveHeight, height: 0.05 * responsiveHeight, fontSize: 0.025 * responsiveHeight }}>Log in</Text>
            <View style={{ ...styles._Inputfieldbase, outline: 'none' }}>
                <View style={styles.inputwithlabel}>
                    <Text style={{ ...styles.label, height: responsiveHeight * 0.03 }}>
                        Enter code
                    </Text>
                    <View style={{ ...styles.input, outline: 'none' }}>
                        <TextInput
                            style={{ ...styles.text, outline: 'none' }}
                            placeholder="Full name"
                            onChangeText={onFullNameChange}
                            secureTextEntry={false} 
                        />
                    </View>
                    <View style={{ ...styles.input, outline: 'none' }}>
                        <TextInput
                            style={{ ...styles.text, outline: 'none' }}
                            placeholder="Password   "
                            onChangeText={onPasswordChange}
                            secureTextEntry={true}
                        />
                    </View>
                </View>
            </View>
        </View>
    )
}
const isWeb = Platform.OS === 'web';
const styles = StyleSheet.create({
    inputfield: {
        flexShrink: 0,
        width: 320,
        alignItems: "flex-start",

    },
    loginTitle: {
        width: 54,
        height: 23,
        fontSize: 18,
        fontWeight: "500",
        lineHeight: 23, // Updated to match the height for better alignment
        textAlign: "center",
        color: Dimensions.get('window').height < Dimensions.get('window').width ? "#20B3D5" : "white",
        alignSelf: "center", // Center the title

    },
    _Inputfieldbase: {
        alignSelf: "stretch",
        flexShrink: 0,
        alignItems: "flex-start",
        rowGap: 6
    },
    inputwithlabel: {
        alignSelf: "stretch",
        flexShrink: 0,
        alignItems: "flex-start",
        rowGap: 6
    },
    label: {

        height: 20,
        fontSize: 14,
        fontWeight: "500",
        lineHeight: 20,

    },
    input: {
        alignSelf: "stretch",
        flexShrink: 0,


        flexDirection: "row",
        alignItems: "center",

        paddingVertical: 10,
        paddingHorizontal: 14,
        borderWidth: 1,
        borderColor: "rgba(208, 213, 221, 1)",
        borderRadius: 8
    },
    text: {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: 0,
        textAlign: "left",
        color: "rgba(152, 162, 179, 1)",
        fontSize: 16,
        fontWeight: "400",
        letterSpacing: 0,
        lineHeight: 24
    }
})
