
const MongoURL = 'https://life-medic-api.azurewebsites.net/api/demo/';
//const MongoURL = 'https://service-api.life-medic.com/api/demo/';
const secret_code ="aw27mo6ois&n1#2498askUIAu3E90!#"


const sendMessageToMongo = async (sender, text, side) => {
  const conversation_id = localStorage.getItem('conversationId')
  try {
    const url = MongoURL + 'messages/' + side;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ sender, text, secret_code, conversation_id}), 
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log('response from mongo:', data);
    return data; // Return the response data for further processing if needed
  } catch (error) {
    console.error('Error communicating with Mongo API:', error);
  }
};

const startConversation = async () => {
  try {
    const response = await fetch(MongoURL + 'create_new_conversation', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ secret_code })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log('response from mongo:', data);
    // Set the conversation ID
    return data.conversation_id;  // Return the response data
  } catch (error) {
    console.error('Error communicating with Mongo API:', error);
  }
};


const getAllInstructions = async () => {
  const param = extractParamFromUrl();
  let url = MongoURL + 'get_all_instructions';

  if (param) {
    // Keep the logic for appending the parameter as it is
    url = MongoURL + `get_doctor_instruction?doctor=${param}`;
  }

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ secret_code }) // Include the secret_code in the request body
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log('response from mongo:', data);
    return data; // Return the response data
  } catch (error) {
    console.error('Error communicating with Mongo API:', error);
  }
};

const extractParamFromUrl = () => {
  try {
    const urlObj = new URL(window.location.href);
    const param = urlObj.searchParams.get('doctor');
    return param // Extracts the value of 'd' parameter
  } catch (error) {
    console.error('Error extracting param from URL:', error);
    return null; // Return null or a default value if extraction fails
  }
};

export { sendMessageToMongo, startConversation, getAllInstructions };
