import React, { useState, useEffect, useRef,useContext } from 'react';
import { View, Text, Animated, TouchableOpacity, Platform ,StyleSheet,Image,ImageBackground} from 'react-native';
import expressionVideo from '../assets/silentDoctor.mp4';
import maximizeIcon from '../assets/maximize.png'
import minimizeIcon from '../assets/minimize.png'
import AppContext from '../AppContext'
import rectangleImage from '../assets/Rectangle.png';
import volumeOffIcon from '../assets/volumeOff.png';
import volumeOnIcon from '../assets/volume.png';
const Avatar = ({ label, ttsAudioUrl,  latestResponse ,talkingVideo,isStreaminRef}) => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const { isStreaming, setIsStreaming } = useContext(AppContext);
  const { responsiveHeight,responsiveWidth } = useContext(AppContext);
  const { setIsRecording } = useContext(AppContext);
  const { recognitionPat} = useContext(AppContext);
  const videoRef = useRef(null);
  const fadeAnim = useRef(new Animated.Value(1)).current;
  const ttsAudioRef = useRef(new Audio(ttsAudioUrl));
  const videoSources = [expressionVideo, ...talkingVideo];
  const [activeVideoIndex, setActiveVideoIndex] = useState(0);


  // Transition duration
  const transitionDuration = 200;

  // Fullscreen styles
  const fullscreenStyle = isFullscreen
    ? { width: responsiveWidth, height:responsiveHeight*0.925 }
    : { width: responsiveHeight*0.3, height: responsiveHeight*0.3, borderRadius: responsiveHeight*0.05 };

  // Container styles for fullscreen mode
  const containerFullscreenStyle = isFullscreen
    ? { position: 'relative', width: responsiveWidth, height: responsiveHeight *0.925, justifyContent: 'center', alignItems: 'center', overflow: 'hidden',zIndex:20,marginBottom:-responsiveHeight*5,marginTop:-responsiveHeight*0.1 }
    : {};


  // Functions to fade in and fade out
  const fadeOut = () => {
    Animated.timing(fadeAnim, {
      toValue: 0,
      duration: transitionDuration,
      useNativeDriver: true,
    }).start();
  };

  const fadeIn = () => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: transitionDuration,
      useNativeDriver: true,
    }).start();
  };

  const subtitleStyle = {
    color: 'white',
    textAlign: 'center',
    fontSize: 0.0214*responsiveHeight,
    lineHeight: 0.0214*responsiveHeight,
  };

  // Function to change the video source
  const changeVideoSource = (isTTS) => {
    fadeOut();

    setTimeout(() => {
      const videoIndex = isTTS ? Math.floor(Math.random() * talkingVideo.length) + 1 : 0; // +1 to skip the expression video which is at index 0
      setActiveVideoIndex(videoIndex);
      fadeIn();
    }, transitionDuration);
  };


  // Video properties
  const videoProps = {
    autoPlay: true,
    playsInline: true,
    loop: true,
    style: fullscreenStyle,
    ref: videoRef,
    muted: true,
  };

  useEffect(() => {
    changeVideoSource();
  }, []);
  // Handle video source change on videoStream update
  useEffect(() => {
    // Function to reset the audio source
    const resetAudioSource = () => {
      if (ttsAudioRef.current) {
        ttsAudioRef.current.pause();
        ttsAudioRef.current.src = ''; // Reset source
        changeVideoSource(false);
       
      }
    };
  
    // Only set up audio if isStreaming is true and ttsAudioUrl is available
    if (ttsAudioUrl && isStreaming) {
      ttsAudioRef.current.src = ttsAudioUrl;
      changeVideoSource(true); // Switch to talking video
      ttsAudioRef.current.play();

      // Change back to the expression video when the audio ends
      ttsAudioRef.current.onended = () => {
        resetAudioSource(); // Also reset the audio source when the audio ends
        changeVideoSource(false); // Switch back to expression video
      };
    } else {
      resetAudioSource(); // Pause and reset the audio if isStreaming is false
    }
    
    // Cleanup function to reset the audio when the component is unmounted or dependencies change
    return () => resetAudioSource();
  
  }, [ttsAudioUrl, isStreaming]); 
  

  // Toggle fullscreen state
  const toggleFullscreen = () => {
    if (recognitionPat){
    
      recognitionPat.stop();
    }
    setIsRecording(false);
    setIsFullscreen(!isFullscreen);
  };
  // Toggle Streaming state
  const toggleStreaming = () => {
   
      setIsStreaming(!isStreaming);
      isStreaminRef.current=!isStreaminRef.current;
    
  };

  const getLimitedResponse = (response) => {
    const lines = response.split('. ').map(line => line.trim()).filter(line => line);
    if (lines.length > 2) {
      return [lines.slice(0, 2).join('. '), '...'].join('. ');
    }
    return lines.join('. ');
  };

  return (
    <View style={[{ margin: 10, alignItems: 'center', justifyContent: 'center', overflow: isFullscreen ? 'hidden' : 'visible' }, containerFullscreenStyle]}>
  
      <Animated.View style={{ opacity: fadeAnim }}>
        {/* Toggle Button for Streaming */}
        {videoSources.map((src, index) => (
        <video
          key={src}
          src={src}
          style={{
            ...fullscreenStyle,
            display: activeVideoIndex === index ? 'block' : 'none',
          }}
          autoPlay
          loop
          muted
          playsInline
        />
      ))}
          <View style={isFullscreen ? styles.recorderFullscreenStyle : styles.iconsOverlay}>
          <ImageBackground source={rectangleImage} style={styles.sharedIconBackground}>
            {/* Volume Toggle Button */}
            <TouchableOpacity onPress={toggleStreaming}>
              <Image source={isStreaming ? volumeOnIcon : volumeOffIcon} style={styles.icon} />
            </TouchableOpacity>

            {/* Maximize Button */}
            <TouchableOpacity onPress={toggleFullscreen}>
              <Image source={isFullscreen?minimizeIcon:maximizeIcon} style={styles.icon} />
            </TouchableOpacity>
          </ImageBackground>
        </View>

     

        {isFullscreen && isStreaming && latestResponse && (
          <View style={{ position: 'absolute', bottom: 60, left: 0, right: 0, backgroundColor: 'transparent', padding: 10 }}>
            <Text style={subtitleStyle} numberOfLines={2}>
              {getLimitedResponse(latestResponse)}
            </Text>
          </View>
        )}

        {!isFullscreen && (
          <View style={{ width: '100%', alignItems: 'center', marginTop: 5 }}>
            <Text style={{ fontSize: 16, textAlign: 'center' }}>{label}</Text>
          </View>
        )}
      </Animated.View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'visible',
    position: 'relative',
  },
  buttonContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: 0,
    
  },
  iconsOverlay: {
    position: 'absolute',
    left: '50%', 
    bottom: 30, 
    transform: [{ translateX: -50 }], 
  
  },
  sharedIconBackground: {
    flexDirection: 'row', 
    padding: 10,
    justifyContent: 'space-between', 
    alignItems: 'center',
    width: 100, 
    height: 35, 
    borderRadius: 10, 
    overflow: 'hidden',
  },
  iconButton: {
    justifyContent: 'center',
    alignItems: 'center',

  },
  icon: {
    width: 30,
    height: 30,
  },
  recorderFullscreenStyle: {
    position: 'absolute',
    left: '50%', 
    bottom: 20, // You can adjust this value as needed to position the icons
    transform: [{ translateX: -50 }], // This will center the icons
    flexDirection: 'row', 
    alignItems: 'center',
    zIndex: 1, // Make sure this is above other elements
  },
});


export default Avatar;
