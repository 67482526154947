// Button.js
import React, { useContext } from 'react';

import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';

import AppContext from '../AppContext'; 


export default function Button({ onPress, isInputFilled }) {

    const {  responsiveHeight } = useContext(AppContext);
    return (
        <TouchableOpacity onPress={onPress} style={{...styles.button,width:responsiveHeight*0.3,marginTop:0.02*responsiveHeight}}>
            <View style={[styles._Buttonbase, isInputFilled && styles.inputFilled]}>
                <Text style={styles.text}>
                    Continue
                </Text>
            </View>
        </TouchableOpacity>
    )
}

const styles = StyleSheet.create({
    button: {
    flexShrink: 0,
    width: 320,
    flexDirection: "row",
    alignItems: "flex-start",
    columnGap: 0,
    borderRadius: 10,
    marginTop:30
    },
    _Buttonbase: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 0,
    backgroundColor: "rgba(166, 228, 242, 1)",
    shadowOffset: {
    width: 0,
    height: 1
    },
 

    shadowRadius: 2,
    shadowColor: "rgb(16, 24, 40)",
    shadowOpacity: 0.05,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    columnGap: 8,
    paddingVertical: 10,
    paddingHorizontal: 16,
    borderWidth: 1,
    borderColor: "rgba(166, 228, 242, 1)",
    borderRadius: 10
    },
    inputFilled: {
        backgroundColor: "#3CC3E2", // Background color when input is filled
    },
    text: {
    flexShrink: 0,
    textAlign: "left",
    color: "rgba(255, 255, 255, 1)",
    fontSize: 16,
    fontWeight: "600",
    letterSpacing: 0,
    lineHeight: 20
    }
    })