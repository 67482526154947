import React, { useContext } from 'react'; // Import useContext
import { View, TouchableOpacity, Image } from 'react-native';
import AppContext from './AppContext';

const Header = ({ onNavigate, navigationIcon }) => {
  // Use useContext inside the functional component
  const { responsiveHeight } = useContext(AppContext);

  return (
    <View style={{ position: 'relative', height: '10%', padding: 0, zIndex: 5 }}> 
      <TouchableOpacity 
        onPress={onNavigate} 
        style={{ 
          position: 'absolute', 
          top: 0, 
          left: 0,
          marginTop: -1
        }} 
      >
        <Image 
          source={navigationIcon} 
          style={{ width: responsiveHeight * 0.25, height: responsiveHeight * 0.2 * 0.25 }} 
          resizeMode="contain" 
        />
      </TouchableOpacity>
    </View>
  );
};

export default Header;
