// DoctorApp.js

import React, { useContext, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import Header from '../Header';
import Avatar from './AvatarDocSIde';
import MessageList from '../MessageList';
import ChatInputDoc from './ChatInputDoc';
import ChatGPTAIService from '../ChatGPTAIService';
import DoctorIcon from '../assets/switchToPat.png';
import UserImage from '../assets/imageDoctor.png';
import BotImage from '../assets/bot.jpg';
import AppContext from '../AppContext';
import { useFocusEffect } from '@react-navigation/native';
import { sendMessageToMongo } from '../MongoDBService'


export default function DoctorApp({ navigation }) {
  const [isSending, setIsSending] = useState(false);
  const { jsonData } = useContext(AppContext);
  const { responsiveWidth, responsiveHeight } = useContext(AppContext);
  const [isFirst, setIsFirst] = useState(false);


  const {
    currentSide, setCurrentSide, navigationToPat, setNavigationToPat,
    doctorMessages, setDoctorMessages,
  } = useContext(AppContext);
  
  const [messages, setMessages] = useState([doctorMessages]);
  
  const navigateToPatientSide = () => {
    setNavigationToPat(true);
    setCurrentSide('patient');
    navigation.navigate('Patient');
  };
  
  
  useFocusEffect(
    React.useCallback(() => {
      setMessages(doctorMessages);
      sendInitialMessage();
      return () => {
        
      };
    }, [currentSide])
    );
    const sendInitialMessage = async () => {
      const existingMessages = doctorMessages;
      
      let initialInstruction = navigationToPat
      ? jsonData.doctor_initial_instruction_advanced
      : jsonData.doctor_initial_instruction;
      
      setMessages(existingMessages);
      let updatedMessages = [...existingMessages, { text: "typing...", sentByUser: false }];
      setMessages(updatedMessages);
      ChatGPTAIService.addMessageToConversation('user', initialInstruction);
      ChatGPTAIService.addMessageWithoutPrompt('doctor', " initial Instruction prompt for physician");
      
      sendMessageToMongo('doctor', initialInstruction, 'doctor')
      const response = await ChatGPTAIService.sendToChatGPT();
      
      updatedMessages = updatedMessages.slice(0, -1);
      updatedMessages.push({ text: response, sentByUser: false });
      setMessages(updatedMessages);
      setDoctorMessages(updatedMessages);
      ChatGPTAIService.addMessageToConversation('assistant', response);
      ChatGPTAIService.addMessageWithoutPrompt('assistant', response);
      
      sendMessageToMongo('assistant', response, 'doctor');
      
  };
  
  
  
  const handleFileSelected = async (file, sentByUser = true) => {
    setIsFirst(false);
    if (isSending) return;
    setIsSending(true);

    setMessages(prevMessages => [...prevMessages, { text:file.fileName, sentByUser }]);
    setDoctorMessages(prevMessages => [...prevMessages, { text:file.fileName, sentByUser }]);

    if (!sentByUser) return;

    setMessages(prevMessages => [...prevMessages, { text: "typing...", sentByUser: false }]);
    setDoctorMessages(prevMessages => [...prevMessages, { text: "typing...", sentByUser: false }]);

    let response;
    

    try {

      ChatGPTAIService.addMessageToConversation('user', file.text + "this is an ocr translation of a file the Physician just send , answer it like you read the file");
      ChatGPTAIService.addMessageWithoutPrompt('doctor', file.text);

      sendMessageToMongo('doctor', file.text , 'doctor');
      response = await ChatGPTAIService.sendToChatGPT();

      setMessages(prevMessages => {
        const updatedMessages = prevMessages.slice(0, -1);
        updatedMessages.push({ text: response, sentByUser: false });
        return updatedMessages;
      });
      setDoctorMessages(prevMessages => {
        const updatedMessages = prevMessages.slice(0, -1);
        updatedMessages.push({ text: response, sentByUser: false });
        return updatedMessages;
      });
      ChatGPTAIService.addMessageToConversation('assistant', response);
      ChatGPTAIService.addMessageWithoutPrompt('assistant', response);

      sendMessageToMongo('assistant', response, 'doctor');

      setIsSending(false);
    } catch (error) {
      console.error('Error in handleSend:', error);
      setIsSending(false);
    }
    setIsFirst(true);
  };
  
  const handleSend = async (text, sentByUser = true) => {
    setIsFirst(false);
    if (isSending) return;
    setIsSending(true);

    setMessages(prevMessages => [...prevMessages, { text, sentByUser }]);
    setDoctorMessages(prevMessages => [...prevMessages, { text, sentByUser }]);

    if (!sentByUser) return;

    setMessages(prevMessages => [...prevMessages, { text: "typing...", sentByUser: false }]);
    setDoctorMessages(prevMessages => [...prevMessages, { text: "typing...", sentByUser: false }]);

    let response;
    let instruction = jsonData.doctorInstruction;
    try {

      ChatGPTAIService.addMessageToConversation('user', text + instruction);
      ChatGPTAIService.addMessageWithoutPrompt('doctor', text);

      sendMessageToMongo('doctor', text + instruction, 'doctor');
      response = await ChatGPTAIService.sendToChatGPT();

      setMessages(prevMessages => {
        const updatedMessages = prevMessages.slice(0, -1);
        updatedMessages.push({ text: response, sentByUser: false });
        return updatedMessages;
      });
      setDoctorMessages(prevMessages => {
        const updatedMessages = prevMessages.slice(0, -1);
        updatedMessages.push({ text: response, sentByUser: false });
        return updatedMessages;
      });
      ChatGPTAIService.addMessageToConversation('assistant', response);
      ChatGPTAIService.addMessageWithoutPrompt('assistant', response);

      sendMessageToMongo('assistant', response, 'doctor');

      setIsSending(false);
    } catch (error) {
      console.error('Error in handleSend:', error);
      setIsSending(false);
    }
    setIsFirst(true);

  };


  return (
    <View style={styles.container}>
      <View style={{
        ...styles.fixedContainer,
        width: responsiveWidth,
        height: responsiveHeight,
        boxShadow: '0px 0px 60px rgba(29, 42, 45, 0.18)'
      }}>
        <Header
          title="Life-Medic- Doctor Side"
          onNavigate={navigateToPatientSide}
          navigationIcon={DoctorIcon}
        />

        <Avatar image={require('../assets/imagePatient.png')} label="Patient" />
        <MessageList
          messages={messages}
          userImage={UserImage}
          botImage={BotImage}
        />
        <ChatInputDoc onSend={handleSend} isSendingDisabled={isSending} onFileSelected={handleFileSelected}  focus={isFirst} />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
  },

  fixedContainer: {
    overflow: 'hidden',
    justifyContent: 'center',
    backgroundColor: 'white',
    borderTopRightRadius: 16,
    borderTopLeftRadius: 16,
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,

  },

});