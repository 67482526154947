import React from 'react';
import { View, Text, Image, StyleSheet } from 'react-native';

import { Dimensions } from 'react-native';

const screenHeight = Dimensions.get('window').height;
const screenWidth = Dimensions.get('window').width;
const aspectRatio = 1500 / 1008;

// Calculate responsive dimensions
const desiredHeightProportion = screenWidth > screenHeight ? 0.85 : 0.5;
const initialContainerHeight = screenHeight < screenWidth ? screenHeight * desiredHeightProportion : screenHeight;

console.log(initialContainerHeight)
const Message = ({ text, sentByUser, userImage, botImage }) => {
  const isUserMessage = sentByUser;
  const containerStyles = [styles.messageContainer, isUserMessage ? styles.userContainer : styles.botContainer];
  const imageStyles = [styles.profilePic, isUserMessage ? styles.userImage : styles.botImage];


  // Function to format time
  const formatTime = (date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    return hours + ':' + minutes + ' ' + ampm;
  };

  // Get current time
  const currentTime = formatTime(new Date());
  const renderText = (text) => {
    // Split the text by '**'
    if (!text){
      return;
    }
    const parts = text.split('**');
    return parts.map((part, index) => {
      // Odd indices are bold
      const isBold = index % 2 === 1;
      return (
        <Text key={index} style={isBold ? { fontWeight: 'bold' } : {}}>
          {part}
        </Text>
      );
    });
  };

  return (
    <View style={containerStyles}>
      {!isUserMessage && <Image source={botImage} style={imageStyles} />}
      <View style={styles.messageBubble}>
        <Text style={isUserMessage ? styles.userText : styles.botText}>
          {renderText(text)}
        </Text>
        <Text style={isUserMessage ? styles.userTime : styles.botTime}>{currentTime}</Text>
      </View>
      {isUserMessage && <Image source={userImage} style={imageStyles} />}
    </View>
  );
};

const styles = StyleSheet.create({
  messageContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: screenWidth > screenHeight ? 0.0447 * initialContainerHeight : 0.00447 * initialContainerHeight,
    marginVertical: 0.00447 * initialContainerHeight,
  },
  userContainer: {
    justifyContent: 'flex-end',
  },
  botContainer: {
    justifyContent: 'flex-start',
  },
  profilePic: {
    width: 0.0357 * initialContainerHeight,
    height: 0.0357 * initialContainerHeight,
    borderRadius: 0.0178 * initialContainerHeight,
  },
  userImage: {
    marginLeft: 0.00715 * initialContainerHeight,
    alignSelf: 'flex-end',
    marginBottom: 0.0357 * initialContainerHeight,
  },
  botImage: {
    marginRight: 0.00715 * initialContainerHeight,
    alignSelf: 'flex-start',
    marginTop: 0.0107 * initialContainerHeight,
  },
  messageBubble: {
    maxWidth: screenHeight > screenWidth ? '45%' : '30%',
    minWidth: '6%',

    borderRadius: 0.00715 * initialContainerHeight,
    padding: 0.00107 * initialContainerHeight,
    flexShrink: 0.0009 * initialContainerHeight,
  },
  userText: {
    color: "rgba(255, 255, 255, 1)",
    backgroundColor: "rgba(32, 179, 213, 1)",
    fontSize: screenWidth > screenHeight ? 0.0203 * initialContainerHeight : 0.0176 * initialContainerHeight,
    lineHeight: 0.0214 * initialContainerHeight,
    borderBottomRightRadius: 0.00715 * initialContainerHeight,
    borderTopLeftRadius: 0.00715 * initialContainerHeight,
    borderBottomLeftRadius: 0.00715 * initialContainerHeight,
    padding: 0.0107 * initialContainerHeight,
    shadowRadius: 2,
    shadowColor: 'rgb(16, 24, 40)',
    shadowOpacity: 0.06,
    shadowOffset: { width: 0, height: 1 },
  },
  botText: {
    color: "rgba(16, 24, 40, 1)",
    backgroundColor: "rgba(242, 244, 247, 1)",
    fontSize: screenWidth > screenHeight ? 0.0203 * initialContainerHeight : 0.0176 * initialContainerHeight,
    lineHeight: 0.0214 * initialContainerHeight,
    borderBottomRightRadius: 0.00715 * initialContainerHeight,
    borderTopRightRadius: 0.00715 * initialContainerHeight,
    borderBottomLeftRadius: 0.00715 * initialContainerHeight,
    padding: 0.0107 * initialContainerHeight,

  },
  userTime: {
    fontSize: 0.0107 * initialContainerHeight,
    lineHeight: 0.0214 * initialContainerHeight,
    color: "rgba(158, 158, 158, 1)",
    textAlign: "right",
    marginTop: 0.00358 * initialContainerHeight,
  },
  botTime: {
    fontSize: 0.0107 * initialContainerHeight,
    lineHeight: 0.0214 * initialContainerHeight,
    color: "rgba(158, 158, 158, 1)",
    textAlign: "left",
    marginTop: 0.00358 * initialContainerHeight,
  },
});

export default Message;
