import React, { useEffect, useRef } from 'react';
import { ScrollView } from 'react-native';
import Message from './Message';

const MessageList = ({ messages, userImage, botImage }) => {
  const scrollViewRef = useRef();

  useEffect(() => {
    scrollViewRef.current?.scrollToEnd({ animated: true });
  }, [messages]); // Dependency array with messages

  return (
    <ScrollView
      style={{ flex: 1 }}
      ref={scrollViewRef} // Attach the ref here
    >
      {messages.map((message, index) => (
        <Message
          key={index}
          text={message.text}
          sentByUser={message.sentByUser}
          userImage={userImage}
          botImage={botImage}
        />
      ))}
    </ScrollView>
  );
};

export default MessageList;
