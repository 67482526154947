
import { View, StyleSheet, Dimensions } from 'react-native';
import React, {useEffect,useContext} from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import Patient from './Patient/Patient';
import DoctorApp from './Doctor/DoctorApp';
import Login from './Login/LoginDemo';
import { AppContextProvider } from './AppContext';
import { generatePDF } from './pdfService';
import emailjs from 'emailjs-com';


const Stack = createNativeStackNavigator();

export default function App() {
 
  const uniqueId = Math.floor(Math.random() * 100000) + 1;
  const sendEmailWithSummary =  async() => {
    const currentTime = new Date();
    const year = currentTime.getFullYear();
    const month = currentTime.getMonth() + 1; // Months are zero-indexed
    const day = currentTime.getDate();
    const hours = currentTime.getHours();
    const minutes = currentTime.getMinutes();
    const conversationDateTime = `${year}-${month}-${day} ${hours}:${minutes}`;
  
    // Calculate the time interval
   
    
    const pdf64 =  await generatePDF(uniqueId,conversationDateTime);
    console.log(pdf64);

    const templateParams = {
      id:uniqueId,
      conversation_date_time: conversationDateTime,
      name:localStorage.getItem('fullname'),
      content:pdf64

    };

    emailjs.send('service_vq3nbag', 'template_f0nrvwn', templateParams, 'OmOO_YXt3yZ8CfLzx')
      .then((response) => {
        console.log('Email successfully sent!', response.status, response.text);
      }, (error) => {
        console.log('Failed to send email:', error);
      });
  };


  const HandleBeforeUnload = (e) => {
    sendEmailWithSummary();
    const message ='are you sure you want to leave?';
    e.returnValue =message;
    return message;
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      sendEmailWithSummary();
    }, 600000); // 600,000 milliseconds = 10 minutes

    return () => {
      clearTimeout(timer); // Clear the timeout if the component unmounts
    };
  }, []);

  useEffect (()=> {

    window.addEventListener('beforeunload', HandleBeforeUnload);

    return ()=> {
      window.removeEventListener('beforeunload',HandleBeforeUnload);
    };

  },[]);

  
  


  return (
    <AppContextProvider>
      <View style={styles.fullScreen}>
        <NavigationContainer>
          <Stack.Navigator initialRouteName="Login">
            <Stack.Screen name="Login" component={Login} options={{ headerShown: false }} />
            <Stack.Screen name="Patient" component={Patient} options={{ headerShown: false }} />
            <Stack.Screen name="DoctorApp" component={DoctorApp} options={{ headerShown: false }} />
          </Stack.Navigator>
        </NavigationContainer>
      </View>
    </AppContextProvider>
  );
}

const styles = StyleSheet.create({
  fullScreen: {
    overflow:'hidden',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: "#3CC3E2",
  }
});