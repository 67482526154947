// AvatarDocSide.js
import { View, Text, Image} from 'react-native';
import React, { useContext } from 'react';
import AppContext from '../AppContext';
const AvatarDocSide = ({ image, label }) => {

  const { responsiveHeight } = useContext(AppContext);

  return (
    <View style={{ margin: 10, alignItems: 'center' }}>
     
        <Image
          source={image}
          style={{ width: responsiveHeight*0.25, height: responsiveHeight*0.25, borderRadius: responsiveHeight*0.04 }}
        />
      
      <View style={{ width: '100%', alignItems: 'center', marginTop: 5 }}> 
          <Text style={{ fontSize: 16, textAlign: 'center' }}>{label}</Text>
        </View>
    </View>
  );
};

export default AvatarDocSide;
