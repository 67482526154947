// LoginDemo.js
import React, { useState, useEffect, useContext } from 'react';
import { View, Image, StyleSheet } from 'react-native';
import InputField from './InputLogin.js';
import Button from './Button.js';
import { getAllInstructions } from '../MongoDBService';
import AppContext from '../AppContext'; 
import { Dimensions } from 'react-native';
import { Platform } from 'react-native';
import { Keyboard } from 'react-native';


export default function LoginDemo({ navigation }) {
  const [password, setPassword] = useState('');
  const { setJsonData } = useContext(AppContext); 
  const { responsiveWidth, responsiveHeight } = useContext(AppContext);
  const [fullName, setFullName] = useState('');
  
  
  useEffect(() => {
    // Fetch instructions when component mounts
    const fetchInstructions = async () => {
      const data = await getAllInstructions();
      if (data) {
        setJsonData(data); // Update the context with fetched data
      }
    };

    fetchInstructions();
  }, []);
  
  const handleLogin = () => {
    // Check if the full name input is empty
    if (!fullName.trim()) {
      alert('Input Error', 'Please enter your full name.'); // Correctly use Alert.alert
      return; // Prevent further execution if full name is empty
    }

    if (password === '1111'&&fullName.trim()) {
      localStorage.setItem('fullname',fullName)
      Keyboard.dismiss();
      navigation.navigate('Patient');
    } else {
      // Handle wrong password case
      alert('Login Error', 'Incorrect password'); // Use Alert instead of alert() for React Native
    }
  };
  const isWeb = Platform.OS === 'web';

  return (
    <View style={styles.container}>
      <View style={{
        ...styles.fixedContainer,
        width:Dimensions.get('window').height>Dimensions.get('window').width ? responsiveWidth * 1500 / 1008 : responsiveWidth,
        height:Dimensions.get('window').height>Dimensions.get('window').width ? responsiveHeight : responsiveHeight,
        boxShadow: '0px 0px 60px rgba(29, 42, 45, 0.18)' // CSS boxShadow property
      }}>
        {Dimensions.get('window').height>800&&Dimensions.get('window').height < Dimensions.get('window').width && <Image source={require('../assets/Login.png')} style={styles.backgroundImage} />}

        <View style={{...styles.rightAlignedContainer,right:responsiveHeight*0.17,top:responsiveHeight*0.375}}>
          <InputField onPasswordChange={(text) => setPassword(text)} onFullNameChange= {(text)=>setFullName(text)}></InputField>
          <Button onPress={handleLogin} isInputFilled={password.length > 0 && fullName.length > 0} />
        </View>

      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
  },

  fixedContainer: {
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    borderTopRightRadius:16,
    borderTopLeftRadius:16,
    borderBottomLeftRadius:16,
    borderBottomRightRadius:16,
 

  },
  backgroundImage: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
  },
  rightAlignedContainer: {
    position: 'absolute',

    alignItems: 'center',
    justifyContent: 'center',

  },
});


