// Patient.js

import React, { useContext, useRef, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import fetchTTS , {fetchAzureTTS} from '../TTsService'


import Header from '../Header';
import Avatar from './Avatar';
import MessageList from '../MessageList';
import ChatInputPat from './ChatInputPat';
import ChatGPTAIService from '../ChatGPTAIService';

import PatientIcon from '../assets/switchDoc.png';
import UserImage from '../assets/imagePatient.png';
import BotImage from '../assets/imageDoctor.png';
import AppContext from '../AppContext';
import { useFocusEffect } from '@react-navigation/native';
import { sendMessageToMongo, startConversation } from '../MongoDBService'
import Doctor1 from '../assets/Doctor1.mp4';
import Doctor2 from '../assets/Doctor2.mp4';
import Doctor3 from '../assets/Doctor3.mp4';




// Adjust width and height based on screen dimensions to maintain aspect ratio
export default function Patient({ navigation }) {

  const { currentSide, setCurrentSide, navigationToDoc, setNavigationToDoc, patientMessages,
  setPatientMessages,isStreaming } = useContext(AppContext);
  const isStreaminRef = useRef(false);
  const { responsiveWidth, responsiveHeight } = useContext(AppContext);
  const [videoSources] = useState([Doctor1, Doctor2, Doctor3]);

  const [messages, setMessages] = useState(patientMessages);
  const navigateToDoctorSide = () => {
    setNavigationToDoc(true);
    setCurrentSide('doctor');
    navigation.navigate('DoctorApp');
  };


  const [latestResponse, setLatestResponse] = useState('');
  const [isSending, setIsSending] = useState(false);
  const { jsonData } = useContext(AppContext);
  const [isFirst, setIsFirst] = useState(false);
  const [sendNum, setSendNum] = useState(0);
  const [ttsAudioUrl, setTtsAudioUrl] = useState(null);
  

  useFocusEffect(
    React.useCallback(() => {
      console.log("Patient screen is focused");


      sendInitialMessage();
      return () => {
        // cleanup if needed
      };
    }, [currentSide])
  );


  const sendInitialMessage = async () => {
    const existingMessages = patientMessages;

    if (existingMessages.length === 0) {
      const initialMessage = "Hello! I am Dr. Paz's AI assistant. How can I help you today?";
      setMessages([{ text: initialMessage, sentByUser: false }]);
      setPatientMessages([{ text: initialMessage, sentByUser: false }]);

      const newConversationId = await startConversation();

      localStorage.setItem('conversationId', newConversationId);
      ChatGPTAIService.addMessageToConversation('system', jsonData.instructions);
      ChatGPTAIService.addMessageToConversation('assistant', initialMessage);
      ChatGPTAIService.addMessageWithoutPrompt('assistant', initialMessage);
      sendMessageToMongo('assistant', initialMessage, 'patient')

      
    } else {
      setMessages(existingMessages);

      // Add a 'typing...' message
      let updatedMessages = [...existingMessages, { text: "typing...", sentByUser: false }];
      setMessages(updatedMessages);

      ChatGPTAIService.addMessageToConversation('user', jsonData.patient_initial_instruction_advanced);
      ChatGPTAIService.addMessageWithoutPrompt('user', jsonData.patient_initial_instruction_advanced);
      sendMessageToMongo('user', jsonData.patient_initial_instruction_advanced, 'patient')

      // Fetch the response and replace 'typing...' with it
      const response = await ChatGPTAIService.sendToChatGPT();
      updatedMessages = updatedMessages.slice(0, -1);
      updatedMessages.push({ text: response, sentByUser: false });
      setMessages(updatedMessages);
      setPatientMessages(updatedMessages);
      ChatGPTAIService.addMessageToConversation('assistant', response);
      ChatGPTAIService.addMessageWithoutPrompt('assistant', response);
      sendMessageToMongo('assistant', response, 'patient');
    }
  };

  const handleFileSelected = async (file, sentByUser = true) => {
    setIsFirst(false);
    if (isSending) return;
    setIsSending(true);

    let fileName = file.fileName
    setMessages(prevMessages => [...prevMessages, { text:fileName, sentByUser }]);
    setPatientMessages(prevMessages => [...prevMessages, { text:fileName, sentByUser }]);
    if (!sentByUser) return;
    
    setMessages(prevMessages => [...prevMessages, { text: "typing...", sentByUser: false }]);
    setPatientMessages(prevMessages => [...prevMessages, { text: "typing...", sentByUser: false }]);
    let response; 
    try {

      ChatGPTAIService.addMessageToConversation('user', file.text + "this is an ocr translation of a file the patient just send , answer it like you read the file");
      ChatGPTAIService.addMessageWithoutPrompt('user', file.text + "this is an ocr translation of a file the patient just send , answer it like you read the file");
      sendMessageToMongo('patient', file.text + "this is an ocr translation of a file the patient just send , answer it like you read the file", 'patient');
      response = await ChatGPTAIService.sendToChatGPT();

      if ( isStreaming) {
        try {
          const ttsData = await fetchAzureTTS(response);
          setTtsAudioUrl(ttsData);
        } catch (a) {

        }
      } else {
        setTtsAudioUrl(null);

      }

      setMessages(prevMessages => {
        const updatedMessages = prevMessages.slice(0, -1);
        updatedMessages.push({ text: response, sentByUser: false });
        return updatedMessages;
      });
      setPatientMessages(prevMessages => {
        const updatedMessages = prevMessages.slice(0, -1);
        updatedMessages.push({ text: response, sentByUser: false });
        return updatedMessages;
      });

      setLatestResponse(response);
      ChatGPTAIService.addMessageToConversation('assistant', response);
      ChatGPTAIService.addMessageWithoutPrompt('assistant', response);

      sendMessageToMongo('assistant', response, 'patient');


      setIsSending(false);
    } catch (error) {
      console.error('Error in handlefileSelected:', error);
      setIsSending(false);
    }
    setIsFirst(true);


  };

  const handleVoiceTranscript = async (transcript) => {
    console.log("Transcript:", transcript);
    handleSend(transcript);
  };

  const handleSend = async (text, sentByUser = true) => {
    setIsFirst(false);
    if (isSending) return;
    setIsSending(true);

    setMessages(prevMessages => [...prevMessages, { text, sentByUser }]);
    setPatientMessages(prevMessages => [...prevMessages, { text, sentByUser }]);

    if (!sentByUser) return;

    setMessages(prevMessages => [...prevMessages, { text: "typing...", sentByUser: false }]);
    setPatientMessages(prevMessages => [...prevMessages, { text: "typing...", sentByUser: false }]);

    let response;
    try {
      if (navigationToDoc) {
        ChatGPTAIService.addMessageToConversation('user', text + jsonData.user_instruction_advanced);
        ChatGPTAIService.addMessageWithoutPrompt('user', text);
        
        sendMessageToMongo('patient', text + jsonData.user_instruction_advanced, 'patient');
        response = await ChatGPTAIService.sendToChatGPT();
      } else {
        ChatGPTAIService.addMessageToConversation('user', text + jsonData.user_instruction);
        ChatGPTAIService.addMessageWithoutPrompt('user', text);
        sendMessageToMongo('patient', text + jsonData.user_instruction_advanced, 'patient');
        response = await ChatGPTAIService.sendToChatGPT();
      }

      if ( isStreaming||isStreaminRef.current) {
        try {
          const ttsData = await fetchAzureTTS(response);
          setTtsAudioUrl(ttsData);
        } catch (a) {

        }
      } else {
        setTtsAudioUrl(null);

      }


      setMessages(prevMessages => {
        const updatedMessages = prevMessages.slice(0, -1);
        updatedMessages.push({ text: response, sentByUser: false });
        return updatedMessages;
      });
      setPatientMessages(prevMessages => {
        const updatedMessages = prevMessages.slice(0, -1);
        updatedMessages.push({ text: response, sentByUser: false });
        return updatedMessages;
      });

      setLatestResponse(response);
      ChatGPTAIService.addMessageToConversation('assistant', response);
      ChatGPTAIService.addMessageWithoutPrompt('assistant', response);

      sendMessageToMongo('assistant', response, 'patient');


      setIsSending(false);
    } catch (error) {
      console.error('Error in handleSend:', error);
      setIsSending(false);
    }
    setIsFirst(true);
  };



  return (
    <View style={styles.container}>
      <View style={{
        ...styles.fixedContainer,
        width: responsiveWidth,
        height: responsiveHeight,
        boxShadow: '0px 0px 60px rgba(29, 42, 45, 0.18)' // CSS boxShadow property
      }}>
        <Header
          onNavigate={navigateToDoctorSide}
          navigationIcon={PatientIcon}
        />
        <Avatar label="Dr. Paz's Avatar" ttsAudioUrl={ttsAudioUrl} onVoiceTranscript={handleVoiceTranscript} latestResponse={latestResponse} talkingVideo={videoSources} isStreaminRef={isStreaminRef} />
        <MessageList
          messages={messages}
          userImage={UserImage}
          botImage={BotImage}
        />
        <ChatInputPat onSend={handleSend} isSendingDisabled={isSending} onFileSelected={handleFileSelected} focus={isFirst}/>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
  },

  fixedContainer: {
    overflow: 'hidden',
    justifyContent: 'center',
    backgroundColor: 'white',
    borderTopRightRadius: 16,
    borderTopLeftRadius: 16,
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
  },

});
