import React, { useState, createContext } from 'react';
import { Dimensions } from 'react-native';

const screenHeight = Dimensions.get('window').height;
const screenWidth = Dimensions.get('window').width;
const aspectRatio = 1500 / 1008;

// Calculate responsive dimensions
const desiredHeightProportion =screenWidth>screenHeight?0.85:0.5; 
const initialContainerHeight = screenHeight<screenWidth?screenHeight * desiredHeightProportion:screenHeight;
const initialContainerWidth = screenHeight<screenWidth?initialContainerHeight * aspectRatio:screenWidth;

const phone = screenHeight>screenWidth&&screenHeight<800?"yes":"no";
localStorage.setItem("phone",phone);

const AppContext = createContext({
  currentSide: 'patient',
  setCurrentSide: () => {},
  navigationToDoc: false, // New state for counting navigations
  setNavigationToDoc: () => {},
  navigationToPat: false, // New state for counting navigations
  setNavigationToPat: () => {},
  patientMessages: [],
  doctorMessages: [],
  setPatientMessages: () => {},
  setDoctorMessages: () => {},
  isStreaming: false,
  setIsStreaming: () => {},
  isRecording: false,
  setIsRecording:()=>{},
  recognitionPat:null,
  setRecognitionPat:()=>{},
  recognitionDoc:null,
  setRecognitionDoc:()=>{},
  jsonData: {}, // New state for JSON data
  setJsonData: () => {}, // Setter function for JSON data
  responsiveWidth:initialContainerHeight,
  setResponsiveWidth:()=>{},
  responsiveHeight:initialContainerWidth,
  SetResponsiveHeight:()=>{},
  fullName:'',
  setFullName:()=>{}
});

export const AppContextProvider = ({ children }) => {
  const [currentSide, setCurrentSide] = useState('patient');
  const [navigationToPat, setNavigationToPat] = useState(false);
  const [navigationToDoc, setNavigationToDoc] = useState(false);
  const [patientMessages, setPatientMessages] = useState([]);
  const [doctorMessages, setDoctorMessages] = useState([]);
  const [isStreaming, setIsStreaming] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [recognitionPat, setRecognitionPat] = useState(null);
  const [recognitionDoc, setRecognitionDoc] = useState(null);
  const [jsonData, setJsonData] = useState({});
  const [responsiveWidth,setResponsiveWidth] =useState(initialContainerWidth);
  const [responsiveHeight,SetResponsiveHeight] =useState(initialContainerHeight);
  const [fullName, setFullName] = useState('');
 


 

  return (
    <AppContext.Provider value={{ currentSide, setCurrentSide,navigationToPat,setNavigationToPat,navigationToDoc,setNavigationToDoc, patientMessages,
        setPatientMessages,
        doctorMessages,
        setDoctorMessages,isStreaming,
        setIsStreaming,isRecording,
        setIsRecording, recognitionPat, setRecognitionPat,recognitionDoc,setRecognitionDoc,jsonData, setJsonData,responsiveWidth,setResponsiveWidth,responsiveHeight,SetResponsiveHeight,fullName,setFullName}}>
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;
