import React, { useState, useRef, useEffect, useContext } from 'react'; // Import useContext
import { View, TextInput, TouchableOpacity, Image, StyleSheet } from 'react-native';
import SpeechToTextRecorderPat from './SpeechToTextRecorderPat';
import OCR from '../OCR'; // Import the OCR component
import AppContext from '../AppContext'; // Import AppContext
import { Keyboard } from 'react-native';

const ChatInputPat = ({ onSend, isSendingDisabled, onFileSelected,focus}) => {
  const [inputText, setInputText] = useState('');
  const inputRef = useRef(null);
  const { responsiveHeight } = useContext(AppContext); // Use useContext inside the component

  useEffect(() => {
    if(localStorage.getItem("phone")=="no"){
    if(focus){
      inputRef.current?.focus();
    }
  }
  }, [focus]);

  const handleSend = () => {
    if (inputText.trim()) {
      onSend(inputText);
      setInputText('');
      Keyboard.dismiss();
      
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault(); 
      handleSend();
      
    }
  };

  // Define styles inside the component to use responsiveHeight
  const styles = StyleSheet.create({
    container: {
      flexDirection: 'row',
      padding: responsiveHeight * 0.01,
      borderTopWidth: 1,
      borderTopColor: '#CFD8DC',
      alignItems: 'center',
      zIndex: 2,
    },
    input: {
      flex: 1,
      backgroundColor: '#FFFFFF',
      marginRight: 10,
      borderRadius: 10,
      borderWidth: 1, 
      borderColor: '#CFD8DC',
      height: responsiveHeight * 0.035,
      alignItems: 'center',
      paddingLeft: 10,

    },
    sendButton: {
      justifyContent: 'center',
    }
  });

  return (
    <View style={styles.container}>
    <OCR onFileSelected={onFileSelected} />

      <TextInput
        ref={inputRef}
        style={{...styles.input,outline:'none'}}
        value={inputText}
        onChangeText={setInputText}
        placeholder="Type message here..."
        placeholderTextColor="#90A4AE"
        onSubmitEditing={handleSend}
        onKeyPress={handleKeyPress}
        editable={!isSendingDisabled}
      />

      <SpeechToTextRecorderPat onTranscript={onSend} />

      <TouchableOpacity
        onPress={handleSend}
        style={styles.sendButton}
        disabled={inputText.trim().length === 0}>
        <Image
          source={inputText.trim().length > 0 ? require('../assets/sendBtnActive.png') : require('../assets/sendBtnInactive.png')}
          style={{ width: responsiveHeight * 0.035, height: responsiveHeight * 0.035 }}
        />
      </TouchableOpacity>
    </View>
  );
};

export default ChatInputPat;
