import React, {  useEffect,useContext } from 'react';
import { TouchableOpacity, Image } from 'react-native';
import AppContext from '../AppContext'; 

const SpeechToTextRecorderDoc = ({ onTranscript }) => {
    const { isRecording, setIsRecording } = useContext(AppContext);
    const { recognitionDoc, setRecognitionDoc } = useContext(AppContext);
    const {currentside}=useContext(AppContext);
    const {  responsiveHeight } = useContext(AppContext);

    const playBeep = (type) => {
        const sound = new Audio(require(`../assets/${type === 'start' ? 'startMic.mp3' : 'stopMic.mp3'}`));
        sound.play();
    };

    useEffect(() => {
        if ('SpeechRecognition' in window || 'webkitSpeechRecognition' in window) {
            const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
            const newRecognition = new SpeechRecognition();
            newRecognition.lang = 'en-US';
            newRecognition.continuous = true;
            newRecognition.interimResults = false;

            newRecognition.onresult = (event) => {
                const last = event.results.length - 1;
                const transcript = event.results[last][0].transcript;
                if (transcript) {
                    onTranscript(transcript);
                }

            };

            newRecognition.onend = () => {
                setIsRecording(false);
                playBeep('end');
                 
            };

            newRecognition.onerror = (error) => {
                console.error('Speech Recognition Error:', error);
            };

            setRecognitionDoc(newRecognition);
        }

        return () => {
            if (recognitionDoc) {
                recognitionDoc.abort();
                
            }
        };
    }, [currentside]);


    const startRecording = () => {
        if (recognitionDoc && !isRecording) {
            playBeep('start');
            recognitionDoc.start();
            setIsRecording(true);
        }
    };

    const stopRecording = () => {
        if (recognitionDoc ) {
            recognitionDoc.stop();
           
            setIsRecording(false);
        }
    };

        
    useEffect(() => {
        if (isRecording) {
            startRecording();
        } else {
            stopRecording();
        }
    }, [isRecording]);

    const handleToggleRecording = () => {
        if (isRecording) {
            stopRecording();
        } else {
            startRecording();
        }
    };

    return (
        <TouchableOpacity
            onPress={handleToggleRecording}
            style={{ justifyContent: 'center', marginRight: 10 }}>
            <Image
                source={isRecording ? require('../assets/micBtnActive.png') : require('../assets/micBtnInactive.png')}
                style={{ width: responsiveHeight*0.035, height: responsiveHeight*0.035 }}
            />
        </TouchableOpacity>
    );
};

export default SpeechToTextRecorderDoc;





